<template lang="pug">
  div
    v-bottom-sheet(v-model="productSheet" max-width="600px" scrollable)
      v-card(flat)
        v-card-text.pa-0(style="color: rgba(0, 0, 0, 1);")
          v-layout.my-6(v-if="isFetching")
            v-row.fill-height.ma-0(align='center' justify='center')
              v-progress-circular.ma-auto(:width="2" :size="50" color="primary" indeterminate)
          div(v-else)
            template(v-for="(product, index) in productWithAddons")
              v-divider(v-if="index !== 0")
              addon-item(:product="product" @clicked="openShowAddons(index)" :index="index")

        v-card-actions.py-4(v-if="!isFetching")
          v-col.pa-0
            v-btn.mb-4(v-if="isMultipleAddon" color="primary500" outlined :style="{'background-color': $vuetify.theme.themes[$theme].basic600T08}" @click="addProduct" :disabled="!showAddButton || checkOutOfStock" block)
              eva-icon.ml-n2.mt-1(name="plus-outline" height="14" width="14" :fill="!showAddButton || checkOutOfStock ? $vuetify.theme.themes[$theme].textColorDisabled : $vuetify.theme.themes[$theme].primary500")
              .button-AA-medium(:style="{'color': !showAddButton || checkOutOfStock ? $vuetify.theme.themes[$theme].textColorDisabled : $vuetify.theme.themes[$theme].primary500}") {{ $t('add_another') }}
            v-btn.mr-2(v-if="productWithAddons.map(m => (m.quantity)).reduce((a, b) => a + b, 0) == 0" color='danger500' depressed block dark @click="clickSave()" :loading="isLoading")
              .button-AA-medium.basic100--text {{ $t('remove_item') }}
            v-btn.mr-2(v-else color='success500' depressed block dark @click="clickSave()" :loading="isLoading")
              .button-AA-medium.basic100--text {{ $t('update_cart') }}

</template>

<script>
import { mapGetters } from 'vuex'
import AddonItem from '@/components/cart/AddonItem.vue'

export default {
  name: 'Addons',
  components: { AddonItem },
  props: {
    open: {
      type: Boolean,
      default: false
    },
    product: {
      type: Object,
      default: () => ({})
    },
    isMultipleAddon: {
      type: Boolean,
      default: false
    },
    isBulkUpdate: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    isLoading: false,
    isFetching: false,
    productWithAddons: [],
    addons: []
  }),
  computed: {
    ...mapGetters({
      cart: 'cart/getTmpCart',
      finalProducts: 'cart/getFinalProducts'
    }),
    productSheet: {
      get () {
        return this.open
      },
      set (newValue) {
        this.$emit('update:open', newValue)
      }
    },
    productData () {
      return this.product.product || this.product
    },
    showAddButton () {
      if (this.productWithAddons.length > 0) {
        return this.productWithAddons[this.productWithAddons.length - 1].quantity > 0
      } else {
        return false
      }
    },
    checkOutOfStock () {
      // check if addon is out of stock
      if (this.product.addons) {
        for (var i = 0; i < this.product.addons.length; i++) {
          const addon = this.product.addons[i]
          if (this.addOnIsOutOfStock(this.cart, this.productData, addon)) {
            // check if out of stock addon is selected for current product 
            const cartProduct = this.product
            if (cartProduct.addons) {
              const count = cartProduct.addons.filter(f => f.product.id === addon.id).map(m => (m.quantity_per_set)).reduce((a, b) => a + b, 0)
              if (count > 0) return true
            }
          }
        }
      }
      if (this.productData.inventory_enabled || this.productData.order_limit_enabled) {
        let totalCount = this.cart.filter(f => f.product.id === this.productData.id).map(m => (m.quantity)).reduce((a, b) => a + b, 0)
        if (this.productData.inventory_enabled && this.productData.order_limit_enabled) {
          if (this.productData.inventory_qty <= totalCount) {
            return true
          }
          if (this.productData.order_limit <= totalCount) {
            return true
          }
        } else if (!this.productData.order_limit_enabled && this.productData.inventory_enabled && this.productData.inventory_qty <= totalCount) {
          return true
        } else if (!this.productData.inventory_enabled && this.productData.order_limit_enabled && this.productData.order_limit <= totalCount) {
          return true
        }
      }
      return false
    }
  },
  mounted () {
    this.fetchAddonProducts()
  },
  methods: {
    fetchAddonProducts () {
      if (this.isMultipleAddon) {
        this.productWithAddons =  this.cart.filter(f => f.product.id === this.productData.id).map(m => { return { ...m, showAddons: false } })
        this.fetchAddons()
      } else {
        this.productWithAddons.push({ ...this.product, showAddons: true })
        this.fetchAddons()
      }
      if (this.productWithAddons.length === 1) {
        this.productWithAddons[0].showAddons = true
      }
    },
    fetchAddons () {
      this.isFetching = true
      this.$axios.get(`/products/${this.productData.id}/addons?page_size=-1`)
        .then((resp) => {
          this.addons = resp.data.results
          this.productWithAddons.forEach((product) => {
            this.addons.forEach(addon => {
              let addonIndex = product.addons ? product.addons.findIndex(f => f.product.id === addon.id) : -1
              if (addonIndex >= 0) {
                this.$set(product.addons[addonIndex], 'product', addon)
              } else {
                const item = {
                  ...addon,
                  id: null,
                  product: addon,
                  quantity_per_set: 0
                }
                if (!product.addons) {
                  product.addons = []
                }
                product.addons.push(item)
              }
            })
          })
          if (this.productWithAddons.length === 0) {
            this.addProduct()
          }
        })
        .catch((resp) => {
          this.$store.commit('message/showMessage', ['error', 'Unable to complete your request. Please try again later'])
          console.log(resp)
        })
        .finally(() => {
          this.isFetching = false
        })
    },
    addProduct () {
      this.closeShowAddons()
      const tmpId = `tmp_${new Date().getTime()}`
      const newaddonprod = {
        id: tmpId,
        product: this.product,
        price: this.product.price,
        quantity: 0,
        addons: this.addons.map((m) => {
          return {
            ...m,
            id: null,
            product: m,
            quantity_per_set: 0
          }
        }),
        showAddons: true
      }
      this.productWithAddons.push(newaddonprod)
      this.$store.commit('cart/addItem', newaddonprod)
    },
    addonInvDisabled () {
      return false
    },
    checkAddonInv () {
      return false
    },
    openShowAddons (idx) {
      this.productWithAddons.forEach((o, index) => {
        if (index != idx) {
          this.$set(o, 'showAddons', false)
        }
      })
      this.$set(this.productWithAddons[idx], 'showAddons', !this.productWithAddons[idx].showAddons)
    },
    closeShowAddons () {
      this.productWithAddons.forEach((o) => {
        this.$set(o, 'showAddons', false)
      })
    },
    clickSave() {
      if (this.isBulkUpdate) {
        this.productSheet = false
        return
      } else if (this.$store.state.cart.tmpCheckout) {
        this.$store.commit('cart/updateLeadCart', { cart: this.cart, pid: this.$store.state.cart.leadPid})
        this.productSheet = false
        return
      }
      this.isLoading = true
      const params = {
        products: this.finalProducts,
      }
      this.axios.patch(`/leads/${this.$store.state.cart.leadPid}/`, params)
        .then((resp) => {
          this.$store.commit('message/showMessage', ['success', 'Product updated!'])
          this.$store.commit('cart/updateCartData', resp.data.products)
          this.productSheet = false
        })
        .catch(() => {
          this.$store.commit('message/showMessage', ['error', 'Unable to complete your request. Please try again later'])
        })
        .finally(() => {
          this.isLoading = false
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.divbox {
  border: 2px solid var(--v-basic400);
  background-color: var(--v-basic200);
  height: 40px;
  max-width: 60px;
  border-radius: 5px;
}
</style>

<i18n>
{
  "en": {
    "update_cart": "Update cart",
    "remove_item": "Remove item"
  }
}
</i18n>