<template lang="pug">
  div
    v-bottom-sheet(v-model="productSheet" max-width="600px" scrollable)
      v-card.pt-4(flat)
        v-card-text.pa-0(style="color: rgba(0, 0, 0, 1);")
          div.px-4(style="overflow-x: hidden;")
            div.d-flex
              div(v-if="productData.variant_for")
                div.s1.primary--text {{ product.quantity > 0 ? quantity + ' x ' + productData.parent_product.name : productData.parent_product.name }}
                div.c1 {{ productData.name }}
              div(v-else-if="productData.has_bundles")
                div.s1.primary--text {{ product.quantity > 0 ? quantity + ' x ' + productData.name : productData.name }}
                template(v-for="(pbundle, index) in product.bundles")
                  div.c1.primary--text(:key="'pbundle-' + index") {{ index + 1 }}. {{ pbundle.name || pbundle.product.display_name || pbundle.product.name }}
              div.s1.primary--text(v-else) {{ product.quantity > 0 ? quantity + ' x ' + productData.name : productData.name }}
              v-spacer
              div.s1.primary--text {{ (product.price || productData.price) * quantity | displayPrice($currency) }}

            div.d-flex.mt-5.justify-center
              div.label.basic600--text.text-uppercase {{ $t('select_quantity') }}
            div.d-flex.mt-4.mb-2.justify-center
              v-btn.my-auto(icon small @click="quantity--" :disabled="quantity < 1")
                eva-icon.mt-1(name="minus-circle" height="24" width="24" :fill="quantity < 1 ? $vuetify.theme.themes[$theme].textColorDisabled : $vuetify.theme.themes[$theme].success600")
              v-layout.divbox.subtitle-1.my-auto.text-center.mx-4(style="width: 100%") 
                .s1.ma-auto {{ quantity }}
              v-btn.my-auto(icon small @click="quantity++" :disabled="checkOutOfStock")
                eva-icon.mt-1(name="plus-circle" height="24" width="24" :fill="checkOutOfStock ? $vuetify.theme.themes[$theme].textColorDisabled : $vuetify.theme.themes[$theme].success600")

        v-card-actions.py-4
          v-btn.mr-2(v-if="quantity == 0" color='danger500' depressed dark block @click="clickSave()" :loading="isLoading")
            .button-AA-medium.basic100--text {{ $t('remove_item') }}
          v-btn.mr-2(v-else color='success500' depressed dark block @click="clickSave()" :loading="isLoading")
            .button-AA-medium.basic100--text {{ $t('update_cart') }}

</template>

<script>

import { mapGetters } from 'vuex'
export default {
  name: 'Product',
  props: {
    open: {
      type: Boolean,
      default: false
    },
    product: {
      type: Object,
      default: null
    }
  },
  data: () => ({
    isLoading: false,
    quantity: 0
  }),
  computed: {
    ...mapGetters({
      cart: 'cart/getTmpCart',
      finalProducts: 'cart/getFinalProducts'
    }),
    productSheet: {
      get () {
        return this.open
      },
      set (newValue) {
        this.$emit('update:open', newValue)
      }
    },
    productData () {
      return this.product.product
    },
    checkOutOfStock () {
      if (this.productData.has_bundles) {
        if (this.quantity >= 1) {
          return true
        }
        return false
      }
      if (this.productData.variant_for && this.productData.parent_product) {
        let totalCount = this.cart.filter(f => f.product.variant_for === this.productData.parent_product.id).map(m => (m.quantity)).reduce((a, b) => a + b, 0)
        if (this.productData.parent_product.inventory_enabled && this.productData.parent_product.order_limit_enabled) {
          if (this.productData.parent_product.inventory_qty <= totalCount) {
            return true
          }
          if (this.productData.parent_product.order_limit <= totalCount) {
            return true
          }
        } else if (!this.productData.parent_product.order_limit_enabled && this.productData.parent_product.inventory_enabled && this.productData.parent_product.inventory_qty <= totalCount) {
          return true
        } else if (!this.productData.parent_product.inventory_enabled && this.productData.parent_product.order_limit_enabled && this.productData.parent_product.order_limit <= totalCount) {
          return true
        }
      }
      if (this.productData.inventory_enabled || this.productData.order_limit_enabled) {
        let totalCount = this.cart.filter(f => f.product.id === this.productData.id).map(m => (m.quantity)).reduce((a, b) => a + b, 0)
        if (this.cart.filter(f => f.product.has_bundles).length > 0) {
          this.cart.filter(f => f.product.has_bundles).forEach(o => {
            if (o.bundles.filter(f => f.product.id === this.productData.id)) {
              totalCount++
            }
          })
        }
        if (this.productData.inventory_enabled && this.productData.order_limit_enabled) {
          if (this.productData.inventory_qty <= totalCount) {
            return true
          }
          if (this.productData.order_limit <= totalCount) {
            return true
          }
        } else if (!this.productData.order_limit_enabled && this.productData.inventory_enabled && this.productData.inventory_qty <= totalCount) {
          return true
        } else if (!this.productData.inventory_enabled && this.productData.order_limit_enabled && this.productData.order_limit <= totalCount) {
          return true
        }
      }
      return false
    }
  },
  watch: {
    quantity: {
      handler () {
        const newItem = { ...this.product }
        newItem.quantity = this.quantity
        this.$store.commit('cart/updateItem', newItem)
      }
    }
  },
  mounted () {
    if (this.product.quantity) {
      this.quantity = this.product.quantity
    }
  },
  methods: {
    clickSave() {
      if (this.$store.state.cart.tmpCheckout) {
        this.$store.commit('cart/updateLeadCart', { cart: this.cart, pid: this.$store.state.cart.leadPid})
        this.productSheet = false
        return
      }
      this.isLoading = true
      const params = {
        products: this.finalProducts,
      }
      this.axios.patch(`/leads/${this.$store.state.cart.leadPid}/`, params)
        .then((resp) => {
          this.$store.commit('message/showMessage', ['success', 'Product updated!'])
          this.$store.commit('cart/updateCartData', resp.data.products)
          this.productSheet = false
        })
        .catch(() => {
          this.$store.commit('message/showMessage', ['error', 'Unable to complete your request. Please try again later'])
        })
        .finally(() => {
          this.isLoading = false
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.divbox {
  border: 2px solid var(--v-basic500);
  background-color: var(--v-basic100);
  height: 32px;
  max-width: 50px;
  min-width: 50px;
  border-radius: 5px;
}
</style>
