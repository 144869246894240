<template lang="pug">
  .cart-item
    div(v-if="getQuantity()")
      v-row(no-gutters)
        v-col.shrink.py-3
          v-img(:src="productData.image ? `https://img.yezza.io/120x120/${productData.image.path}` : require('@/assets/images/image_placeholder.png')" max-width="48px" width="48px" height="48px")
        v-col.py-3.pl-2
          v-row(no-gutters)
            v-col(cols="7")
              div.p2.primary--text(v-if="!productData.variant_for") {{ (productData.name || product.name) | capitalize }}
              .addons-layout.mt-1(v-if="productData.has_addons")
                div(v-for="(item, index) in product.addons.filter(item => item.quantity_per_set > 0)" :key="'addon-' + index")
                  div.c1.basic600--text + {{ item.name }} {{ item.quantity_per_set > 1 ? 'x ' + item.quantity_per_set : '' }}
                  div.c1.danger500--text(v-if="checkSoldOut(item)") {{ item.product.inventory_qty > 0 ? $t('only_count_left_in_stock', { count: item.product.inventory_qty }) : $t('sold_out') }}

              .bundles-layout(v-if="productData.has_bundles")
                template(v-for="(item, index) in product.bundles")
                  div.c1.basic600--text(:key="'bundle-' + index") {{ index + 1 }}. {{ (item.name || item.product.name) }}
                  .notes-layout(v-if="item.product_notes_data && item.product_notes_data.length > 0")
                    div(v-for="(pnitem, index) in item.product_notes_data" :key="'note-' + index")
                      div.basic600--text.c1.ml-2 - 
                        strong {{ pnitem.name }}: 
                        | {{ pnitem.data }}
                  div.c1.danger500--text(v-if="checkSoldOut(item)") {{ item.product.inventory_qty > 0 ? $t('only_count_left_in_stock', { count: item.product.inventory_qty }) : $t('sold_out') }}

              .variants-layout(v-if="productData.variant_for")
                div.p2.primary--text {{ productData.parent_product.name | capitalize }}
                div.c1.basic600--text.mt-1 {{ productData.name }}

              .notes-layout.mt-1(v-if="product.product_notes_data && product.product_notes_data.length > 0")
                div(v-for="(item, index) in product.product_notes_data" :key="'note-' + index")
                  div.basic600--text.c1
                    strong {{ item.name }}: 
                    | {{ item.data }}
                
              .sold-out(v-if="checkSoldOut(product)")
                div.c1.danger500--text {{ productData.inventory_qty > 0 ? `Only ${productData.inventory_qty} left in stock` : 'Sold out' }}
            v-col(cols="1")
              div.p2.primary-text.text-center {{ getQuantity() }}
            v-col(cols="4")
              div.p2.primary-text.text-right {{ getProductPrice() | displayPrice($currency) }}
              div.button-Aa-small.info500--text.mt-1.clickable.text-right(v-if="!isReadOnly" @click="openEditProduct") {{ $t('edit') }}
            
    .product(style="max-width:500px")
      product(v-if="openProduct" :open.sync="openProduct" :product="product")

    .productAddons(style="max-width:500px")
      addons(v-if="openAddons" :open.sync="openAddons" :product="product")

</template>

<script>
import { mapGetters } from 'vuex'
import Product from '@/components/cart/Product.vue'
import Addons from '@/components/cart/Addons.vue'

export default {
  name: 'CartItem',
  components: { Product, Addons },
  props: {
    product: {
      type: Object,
      default: null
    },
    isReadOnly: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    showAddProductDialog: false,
    openProduct: false,
    openVariants: false,
    openAddons: false,
  }),
  computed: {
    ...mapGetters({
      cart: 'cart/getCart'
    }),
    productData () {
      return this.product.product
    }
  },
  methods: {
    checkSoldOut(item) {
      if (item.product.computed_inventory_qty != null) {
        return item.product.computed_inventory_qty < this.getQuantity()
      }
      return item.product.inventory_enabled && item.product.inventory_qty < item.quantity
    },
    getProductPrice () {
      let price = this.product.price || this.productData.price
      if (this.product.addons) {
        this.product.addons.forEach((item) => {
          price += item.price * item.quantity_per_set
        })
      }
      return price * this.product.quantity
    },
    getQuantity () {
      return this.product.quantity
    },
    openEditProduct () {
      this.$store.commit('cart/syncTmpCart')
      if (this.productData.has_addons) {
        this.openAddons = true
      } else {
        this.openProduct = true
      }
    }
  }
}
</script>

<style scoped>
.quantity-box {
  border: 1px solid black;
  border-radius: 6px;
  height: 24px;
  width: 46px;
  color: #D11184;
}
</style>
