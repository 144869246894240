import { render, staticRenderFns } from "./Addons.vue?vue&type=template&id=198a60a7&scoped=true&lang=pug&"
import script from "./Addons.vue?vue&type=script&lang=js&"
export * from "./Addons.vue?vue&type=script&lang=js&"
import style0 from "./Addons.vue?vue&type=style&index=0&id=198a60a7&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "198a60a7",
  null
  
)

/* custom blocks */
import block0 from "./Addons.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBottomSheet } from 'vuetify/lib/components/VBottomSheet';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBottomSheet,VBtn,VCard,VCardActions,VCardText,VCol,VDivider,VLayout,VProgressCircular,VRow})
