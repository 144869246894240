<template lang="pug">
  .addon-item
    div.d-flex.pa-4(:style="{'background-color': (product.quantity >= 1  && !product.showAddons)? $vuetify.theme.themes[$theme].infoT08 : ''}" @click="$emit('clicked', index)")
      div
        div.s1.primary--text {{ product.quantity >= 1 ? product.quantity + ' x ' + productData.name : productData.name }}
        template(v-for="addon in product.addons")
          div.p2.basic600--text(v-if="addon.quantity_per_set > 0") + {{ addon.name }}
      v-spacer
      div.s1.primary--text {{ ((product.price + product.addons.map(m => (m.price * m.quantity_per_set)).reduce((a, b) => a + b, 0)) * product.quantity) | displayPrice($currency) }}

    v-expand-transition
      div.px-4(v-if="product.quantity == 0 || product.showAddons")
        div.label.basic600--text.text-uppercase {{ $t('add_ons') }}
        v-card.px-2.mt-2(outlined)
          template(v-for="addon in product.addons")
            v-row.my-3(no-gutters align="center")
              v-col(cols="8")
                v-checkbox.pt-0.mt-0.p1(v-model="addon.quantity_per_set" color="primary" :value="1" hide-details :disabled="addon.quantity_per_set < 1 && (addOnIsOutOfStock(cart, product, addon) || addOnWillOutOfStock(cart, product, addon))" @change="product.quantity === 0 ? enableQuantity() : ''; updateLeadCart()")
                  template(v-slot:label)
                    div.p1.primary--text {{ addon.name }}
              v-col(cols="4" align="right")
                div.p2.basic600--text + {{ addon.price | displayPrice($currency) }}

        div.d-flex.justify-center.my-4
          v-btn.my-auto(icon small @click="product.quantity--" :disabled="product.quantity < 1")
            eva-icon.mt-1(name="minus-circle" height="24" width="24" :fill="product.quantity < 1 ? $vuetify.theme.themes[$theme].textColorDisabled : $vuetify.theme.themes[$theme].success600")
          v-layout.divbox.subtitle-1.my-auto.text-center.mx-4(style="width: 100%") 
            .s1.ma-auto {{ product.quantity }}
          v-btn.my-auto(icon small @click="product.quantity++; product.showAddons = true" :disabled="checkOutOfStock")
            eva-icon.mt-1(name="plus-circle" height="24" width="24" :fill="checkOutOfStock ? $vuetify.theme.themes[$theme].textColorDisabled : $vuetify.theme.themes[$theme].success600")      
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'AddonItem',
  props: {
    product: {
      type: Object,
      default: null
    },
    index: {
      type: Number,
      default: 0
    }
  },
  data: () => ({
  }),
  computed: {
    ...mapGetters({
      cart: 'cart/getTmpCart'
    }),
    productData () {
      return this.product.product
    },
    checkOutOfStock () {
      // check if addon is out of stock
      for (var i = 0; i < this.product.addons.length; i++) {
        const addon = this.product.addons[i]
        if (this.addOnIsOutOfStock(this.cart, this.productData, addon)) {
          // check if out of stock addon is selected for current product 
          const cartProduct = this.product
          if (cartProduct.addons) {
            const count = cartProduct.addons.filter(f => f.product.id === addon.id).map(m => (m.quantity_per_set)).reduce((a, b) => a + b, 0)
            if (count > 0) return true
          }
        }
      }
      if (this.productData.inventory_enabled || this.productData.order_limit_enabled) {
        let totalCount = this.cart.filter(f => f.product.id === this.productData.id).map(m => (m.quantity)).reduce((a, b) => a + b, 0)
        if (this.productData.inventory_enabled && this.productData.order_limit_enabled) {
          if (this.productData.inventory_qty <= totalCount) {
            return true
          }
          if (this.productData.order_limit <= totalCount) {
            return true
          }
        } else if (!this.productData.order_limit_enabled && this.productData.inventory_enabled && this.productData.inventory_qty <= totalCount) {
          return true
        } else if (!this.productData.inventory_enabled && this.productData.order_limit_enabled && this.productData.order_limit <= totalCount) {
          return true
        }
      }
      return false
    }
  },
  watch: {
    product: {
      deep: true,
      handler () {
        this.updateLeadCart()
      }
    }
  },
  methods: {
    updateLeadCart () {
      // const product = { ...this.cart.filter(f => f.id === this.product.cartId)[0] }
      // product.quantity = this.product.quantity
      // product.addons = this.product.addons.map((m) => { return { id: m.edit_id, quantity_per_set: m.quantity_per_set, price: m.price, product: m } })
      this.$store.commit('cart/updateItem', this.product)
    },
    enableQuantity () {
      if (this.checkOutOfStock) {
        return
      }
      this.product.quantity = 1
    }
  }
}
</script>

<style scoped>
.divbox {
  border: 2px solid var(--v-basic500);
  background-color: var(--v-basic100);
  height: 32px;
  max-width: 50px;
  min-width: 50px;
  border-radius: 5px;
}
</style>
